import { register } from 'swiper/element/bundle';
import Swiper from 'swiper';

// Register Swiper
register();

const initSliders = () => {
  const swiperContainer = document.querySelector('#swiper-navigation');

  if (swiperContainer) {
    const initialSlideIndex =
      parseInt(swiperContainer.getAttribute('data-initial-slide'), 10) || 0;

    const nextButton = swiperContainer.querySelector('.custom-button-next');
    const prevButton = swiperContainer.querySelector('.custom-button-prev');
    const pagination = swiperContainer.querySelector('.swiper-pagination');
    let centered = false;

    if (initialSlideIndex > 1) {
      centered = true;
    }

    // eslint-disable-next-line no-new
    new Swiper(swiperContainer, {
      centeredSlides: centered,
      initialSlide: initialSlideIndex,
      navigation: {
        nextEl: nextButton,
        prevEl: prevButton,
      },
      pagination: {
        el: pagination,
        clickable: true,
      },
      slidesPerView: 'auto',
      spaceBetween: 0,
    });
  }
};

export default initSliders;

(window as any).initSliders = initSliders;

initSliders();
